import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

import cls from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'variant2' | 'variant3'
  iconPath?: string
  iconPosition?: 'left' | 'right'
  className?: string
}

export function Button(props: IButton) {
  const {
    variant = 'default',
    iconPath,
    iconPosition = 'left',
    className,
    children,
    ...rest
  } = props

  return (
    <button
      {...rest}
      className={classNames(cls.button, cls[variant], className)}
    >
      {iconPath && iconPosition === 'left' && (
        <img src={iconPath} alt="icon-left" className={cls.icon__left} />
      )}
      {children}
      {iconPath && iconPosition === 'right' && (
        <img src={iconPath} alt="icon-right" className={cls.icon__right} />
      )}
    </button>
  )
}
