import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import InvestPlatformMobileImg from 'shared/assets/images/about-us/InvestPlatformMobileImg.webp'

import cls from './AboutUs.module.scss'

export const AboutUs = () => {
  const {
    store: { isMobile, isMobileSmall },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', cls.page)} id="aboutUs">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <img src={InvestPlatformMobileImg} alt="InvestPlatformMobileImg" />
        </div>

        <div className={cls.right_block}>
          <div className={cls.card}>
            <h3 className={cls.card__title}>О нас</h3>

            <p className={cls.card__desc}>
              <span>Freedom Finance</span> - дочерняя компания Freedom Holding
              Corp с капитализацией $3,8 млрд. Мы являемся{' '}
              <span>единственным брокером</span>, представленным в Европе и
              Азии, с листингом на Nasdaq.
            </p>

            <p className={cls.card__desc}>
              Обладаем лицензией <span>CySEC</span> и долгосрочным кредитным
              рейтингом "BB" от <span>Standard & Poor's Global Ratings</span>.
              Подотчетны регуляторам — CySEC и SEC. Прозрачны, надежны и открыты
              для каждого.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
