import classNames from 'classnames'

import { Promotion } from './Promotion'
import { OpenAccount } from './OpenAccount'
import { RefundDeposit } from './RefundDeposit'
import { ChooseUs } from './ChooseUs'
import { ProtectMoney } from './ProtectMoney'
import { AboutUs } from './AboutUs'
import { HovInvest } from './HowInvest'
import { Contacts } from './Contacts'

import cls from './Home.module.scss'

export const HomePage = () => {
  return (
    <div className={classNames(cls.home)}>
      <Promotion />
      <OpenAccount />
      <RefundDeposit />
      <ChooseUs />
      <ProtectMoney />
      <AboutUs />
      <HovInvest />
      <Contacts />
    </div>
  )
}
