import classNames from 'classnames'

import StarFullIcon from 'shared/assets/icons/StarFullIcon.webp'
import StarRatingIcon from 'shared/assets/icons/StarRatingIcon.webp'

import cls from './Rating.module.scss'

interface Rating {
  className?: string
}

export const Rating = (props: Rating) => {
  const { className } = props

  return <div className={classNames(cls.star_rating, className)}>
    <p className={ cls.star_rating__desc1 }>Превосходно 4,5/5</p>
    <div className={ cls.star_rating__stars }>
      <img src={ StarFullIcon } alt="StarFullIcon1" />
      <img src={ StarFullIcon } alt="StarFullIcon2" />
      <img src={ StarFullIcon } alt="StarFullIcon3" />
      <img src={ StarFullIcon } alt="StarFullIcon4" />
      <img src={ StarRatingIcon } alt="StarRatingIcon" />
    </div>
    <p className={ cls.star_rating__desc2 }>+124 отзыва на Google Play</p>
  </div>
}